.formbuilder-element {
    background-color: $brand-primary;
    padding: 3px 8px;
    color: white;
    z-index: 10;
    cursor: default;
}

#draggable .formbuilder-element {
    display: inline-block;
    white-space: nowrap;
    margin: 3px;
    cursor: default;

    &.ui-draggable-dragging {
        margin: 0;
    }
}

#sortable .formbuilder-element {
    display: block;
    margin: 10px 0;
}