#login-box {
	max-width: 320px;
	margin: 0 auto;
	position: relative;
	top: 40%;
	transform: translateY(-50%);

	.form-group {
		margin: 0;
	}
}

#login-buttons {
	margin-top: 40px;
}

#back-to-login {
	margin-bottom: 10px;

	a {
		color: #999;

		&:hover, &:active {
			color: #666;
		}
	}
}
