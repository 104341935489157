@import "util";

@import "pmdAlert";
@import "login";
@import "sidepanel";
@import "formbuilder";

body, html {
	height: 100%;
	font-size: 12px;
}

h1.page-title {
    font-weight: 400;
    font-size: 200%;
    margin-bottom: 18px;
	margin-top: 22px;
	margin-left: 15px;
}

textarea {
    resize:vertical;
}

a {
	color: $brand-primary;
	cursor: pointer;
}

.btn {
	min-width: auto;
}

dt {
	font-weight: normal;
}

dt, dd {
	margin-bottom: 3px;
}

h2.pmd-card-title-text {
	text-transform: uppercase;
	font-size: 1.15rem;
	font-weight: bold;
}

.pmd-ripple-effect.pmd-ripple-dark .ink {
	background-color: rgba(0, 0, 0, 0.2);
}

.logo {
	.logo-esb {
		fill:#FFFFFF;
	}

	.logo-j, .logo-o, .logo-b {
		fill:#B8C2D1;
	}

	&.logo-animated {
		.logo-j, .logo-o, .logo-b {
			animation: logo-animation 0.5s forwards;
			opacity: 0;
		}

		.logo-j {
			animation-delay: 0.5s;
		}

		.logo-o {
			animation-delay: 0.65s;
		}

		.logo-b {
			animation-delay: 0.8s;
		}
	}

	&.logo-invert {
		.logo-esb {
			fill: $brand-primary;
		}
	}
}

@keyframes logo-animation {
	0% {
		opacity: 0;
		transform: translateY(-30px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

.pmd-card { // Margin collapse fix! May cause problems!
	padding: 0;

	&:before, &:after {
		content: ' ';
		display: table;
	}
}

a .pmd-card-title:hover {
	background-color: $hover-gray;
}

.pmd-card-body.no-margins {
	padding: 0;
	margin: 0;
}

.pmd-tabs .pmd-tab-active-bar {
	width: 0px;
	background-color: $brand-secondary;
}

.circle {
	width: 40px;
	height: 40px;
	display: block;
	border-radius: 50%;
	background-color: $brand-primary;
	text-align: center;
	color: white;
	font-size: 120%;
	position: relative;
	padding-top: 7px;
}

.statistic-icon {
	width: 64px;
	height: 64px;
	margin: 0 auto;
	color: $brand-primary;
	border: 4px solid $brand-primary;
	border-radius: 50%;
	text-align: center;
	font-size: 32px;
	padding-top: 1px;
}

.statistic-number {
	text-align: center;
	font-size: 170%;
	letter-spacing: -2px;
	line-height: 1.5;
}

.statistic-text {
	text-align: center;
	font-size: 100%;
	color: $gray;
	line-height: 1;
	margin-bottom: 3px;
}

.pmd-checkbox [type="checkbox"]:checked + .pmd-checkbox-label:before {
    background-color: $brand-secondary;
}

.pmd-checkbox [type="checkbox"]:checked + .pmd-checkbox-label:before {
	border: none;
}

.pmd-card-title {
	margin-bottom: 0px;
	padding-bottom: 16px;
}

.pmd-card-title ~ .pmd-card-body {
	margin-top: 0px;
}

.bootstrap-datetimepicker-widget {
	.day.today span {
		border: 2px solid $brand-success;
	}

	.topdateinfo, table td.day.active:hover span, table td.hour.active:hover span, table td.minute.active:hover span, table td.second.active:hover span {
		background-color: $brand-primary;
	}

	table td.active span, table td.day.active:hover span {
		background-color: $brand-success;
	}

	.next, .prev {
		color: #aaa;
	}
}

#top-bar {
	background-color: $top-bar-background;
	width: 100%;
	height: $top-bar-height;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 20;
	color: white;
	overflow: hidden;

	.top-bar-left {
		float: left;
	}

	.top-bar-right {
		float: right;
	}

	.top-bar-block {
		display: inline-block;
		line-height: 64px;
		padding: 0 20px;

		&.top-bar-hover:hover {
			background-color: lighten($top-bar-background, 10%);
			cursor: pointer;
		}
	}

	#search {
		line-height: 2;

		input {
			color: black;
			border: 1px solid #ccc;
			border-radius: 2px;
			padding: 0;
			padding: 5px 10px;
			width: 200px;
		}
	}

	.logo {
		width: 160px;
		margin-top: 10px;
		height: 40px;
	}

	#user-name {
		font-size: 0.8em;

		i {
			color: grey;
			margin-left: 5px;
		}
	}

	#alerts {
		color: grey;
	}
}

dl.row > dd {
	min-height: 20px;
}

.pmd-textfield.pmd-textfield-big textarea.form-control {
	height: 400px;
}

.textBlock {
	padding: 3px 5px;
	font-size: 1rem;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
}

.checkbox-inline.pmd-checkbox {
	line-height: 2;
}

tr.person-indextable-highlight td {
	font-weight: bold;
}

#sidepanel a {
	text-decoration: none;
}

@media (min-width: 768px) {
	.dl-horizontal dt {
		text-align: left;
	}
}

.company-table-highlight {
	color: red;
}

.subtitle {
	font-size: 1rem;
}

tr.row-highlight-employee-table {
	font-weight: bold;
}

.col-sm-12 {
	min-width: 100%;
	width: auto;
}

.form-inline tfoot input.form-control {
	width: 100%;
}