#sidepanel {
	width: $sidepanel-width-off;
	max-width: 100vw;
	height: 100%;
    overflow-x: hidden;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 10;
	padding-top: $top-bar-height;
	transition: width $sidepanel-animation-time;
	background-color: $sidepanel-background;
	
	.dropdown button {
		width: 100%;
		text-align: left;
	}
	
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		li {
			width: $sidepanel-width;
			color: $sidepanel-color;
			padding: 10px 18px;
			font-weight: 300;
			background-color: $sidepanel-background;
			transition: padding-left 0.1s;

			i {
				vertical-align: middle;
				font-size: 130%;
				margin-right: 20px;
			}

			&.heading {
				padding: 3px 20px;
			}

			&.active {
				color: white;
			}

			&:hover, &:active {
				background-color: #fff;
				color: #333;
				@include pmd-z-depth(1);
				z-index: 1;
				position: relative;

				i {
					color: $sidepanel-background;
				}
			}
		}
	}
	
	&.on {
		width: $sidepanel-width;
		@include pmd-z-depth(0);

		li.active, li:hover {
			padding-left: 40px;
		}
	}
}

#sidepanel ul ul li {
	padding: 3px 18px;
	padding-left: 82px !important;
}

#sidepanel-button {
	width: 100%;
	@include pmd-z-depth(0);
	color: $sidepanel-color;
	background-color: transparent;
	height: $sidepanel-button-height;
	padding-right: 26px;
	text-align: right;
	font-size: 20px;
	padding-top: 5px;

	transition: all $sidepanel-animation-time;
	
	&:hover {
		color: white;
		cursor: pointer;
		background-color: lighten($sidepanel-background, 10%);
	}
}

#sidepanel-button:before {
	content: "\f0da";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
}

#sidepanel-button.on:before {
	content: "\f0d9";
}


#app {
	padding-top: $top-bar-height;
	padding-left: $sidepanel-width-off;
	width: 100%;
	transition: padding-left $sidepanel-animation-time;

	&.on {
		padding-left: $sidepanel-width;
	}
}

.pmd-badge[data-badge]::after {
	background: $brand-secondary;
	width: 17px;
	height: 17px;
	top: -7px;
	right: -7px;
	font-size: 11px;
}