.pmd-alert-container {
	.pmd-alert {
		&.success{
			background-color: $brand-success;
		}
	}

	.pmd-alert-close {
		color: rgba(255, 255, 255, 0.8);

		&:hover {
			color: rgba(255, 255, 255, 1);
		}
	}
}