@import "variables";
@import "bootstrap/variables";

@mixin pmd-z-depth($depth:-1) {
    @if $depth == -1 {
        box-shadow: none;
    } @else if $depth == 0 {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    } @else if $depth == 1 {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    } @else if $depth == 2 {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    } @else if $depth == 3 {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    } @else if $depth == 4 {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    } @else if $depth == 5 {
        box-shadow: 0 24px 48px rgba(0,0,0,0.30), 0 20px 14px rgba(0,0,0,0.22);
    }
}

@mixin form-colors($property) {
	&.red {
		#{$property}: $form-red;
	}
	
	&.yellow {
		#{$property}: $form-yellow;
	}

	&.green {
		#{$property}: $form-green;
	}
}